import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../../components/modal";
import Table from "../../components/table";
import { base_url } from "../../data/consts";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import * as XLSX from "xlsx"; // Import SheetJS
import { saveAs } from "file-saver"; // Import FileSaver
import "./style.css";

function Tahweelat() {
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const [data, setData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [choosedTahweelat, setChoosedTahweelat] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTahweelat, setSelectedTahweelat] = useState(null);

  useEffect(() => {
    getTahweelat();
  }, []);

  const getTahweelat = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://baseeta-form.com/basita/getAllTahweels.php"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching tahweelat data:", error);
      toast.error(
        language === "ar"
          ? "حدث خطأ أثناء جلب بيانات التحويلات."
          : "An error occurred while fetching tahweelat data."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAction = (action, tahweelat) => {
    if (action === "View") {
      setSelectedTahweelat(tahweelat);
    }
    // Add more actions like Edit, Delete as needed
  };

  const exportToExcel = () => {
    if (!data || data.length === 0) {
      toast.error(
        language === "ar"
          ? "لا توجد بيانات للتصدير."
          : "No data available for export."
      );
      return;
    }

    // Flatten the data to handle nested agent_info
    const flattenedData = data.map((item) => ({
      "Tahweel ID": item.tahweel_id,
      "Agent Name": item.agent_info?.agent_name || "N/A",
      "Total Price (EGP)": item.total_price,
      "Baseeta Discount (EGP)":
        item.total_price - item.total_price_after_discount,
      "Price After Discount (EGP)": item.total_price_after_discount,
      "Paid At": item.paid_at,
      "Agent Phone": item.agent_info?.agent_phone || "N/A",
    }));

    // Create a worksheet from the flattened data
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tahweelat");

    // Generate a buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the buffer
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    // Save the file using FileSaver
    saveAs(dataBlob, "Tahweelat_Data.xlsx");
  };

  const headers = [
    {
      label: language === "ar" ? "رقم التحويل" : "Tahweel ID",
      dataIndex: "tahweel_id",
    },
    {
      label: language === "ar" ? "اسم الوكيل" : "Agent Name",
      type: "children",
      children: ({ row }) => row?.agent_info.agent_name || "N/A",
    },
    {
      label: language === "ar" ? "المبلغ" : "Price",
      dataIndex: "total_price",
      render: (text) => `${text} EGP`, // Adjust currency as needed
    },
 
    {
      label: language === "ar" ? "تم الدفع في" : "Paid At",
      dataIndex: "paid_at",
    },
    {
      label: language === "ar" ? "هاتف الوكيل" : "Agent Phone",
      type: "children",
      children: ({ row }) => row?.agent_info.agent_phone || "N/A",
    },
  ];

  const handleSelectRow = (row) => {
    row.choosed = !row?.choosed;
    if (row?.choosed) {
      setChoosedTahweelat([...choosedTahweelat, row]);
    } else {
      setChoosedTahweelat(
        choosedTahweelat.filter((item) => item?.tahweel_id !== row.tahweel_id)
      );
    }
    setData([...data]);
  };

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="add-container">
              <div className="div">
                <ContentNav
                  head={language === "ar" ? "صفحة التحويلات" : "Tahweelat Page"}
                />
              </div>
            </div>

            {/* Export Button */}
            <div className="export-container">
              <button className="btn btn-primary" onClick={exportToExcel}>
                {language === "ar" ? "تصدير إلى Excel" : "Export to Excel"}
              </button>
            </div>

            <Table
              headers={headers}
              body={data}
              loading={loading}
              classess={["table-tc"]}
            />

            {/* Modal for Viewing Details */}
            {selectedTahweelat && (
              <Modal
                open={!!selectedTahweelat}
                toggle={setSelectedTahweelat}
                headerTitle={
                  language === "ar" ? "تفاصيل التحويل" : "Tahweel Details"
                }
                children={
                  <div className="tahweel-details">
                    <p>
                      <strong>
                        {language === "ar" ? "رقم التحويل:" : "Tahweel ID:"}
                      </strong>{" "}
                      {selectedTahweelat.tahweel_id}
                    </p>
                    <p>
                      <strong>
                        {language === "ar" ? "اسم الوكيل:" : "Agent Name:"}
                      </strong>{" "}
                      {selectedTahweelat.agent_info?.agent_name || "N/A"}
                    </p>
                    <p>
                      <strong>
                        {language === "ar" ? "المبلغ  :" : " Price:"}
                      </strong>{" "}
                      {selectedTahweelat.total_price} EGP
                    </p>
                    <p>
                      <strong>
                        {language === "ar"
                          ? "السعر بعد الخصم:"
                          : "Price After Discount:"}
                      </strong>{" "}
                      {selectedTahweelat.total_price_after_discount} EGP
                    </p>
                    <p>
                      <strong>
                        {language === "ar" ? "تم الدفع في:" : "Paid At:"}
                      </strong>{" "}
                      {selectedTahweelat.paid_at}
                    </p>
                    <p>
                      <strong>
                        {language === "ar" ? "هاتف الوكيل:" : "Agent Phone:"}
                      </strong>{" "}
                      {selectedTahweelat.agent_info?.agent_phone || "N/A"}
                    </p>
                    <p>
                      <strong>
                        {language === "ar"
                          ? "البريد الإلكتروني للوكيل:"
                          : "Agent Email:"}
                      </strong>{" "}
                      {selectedTahweelat.agent_info?.agent_email || "N/A"}
                    </p>
                  </div>
                }
              />
            )}

            {/* Implement other modals as needed */}
          </div>
        }
      />
    </div>
  );
}

export default Tahweelat;
