import { useSelector } from "react-redux";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import "./style.css";
import Table from "../../components/table";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import { base_url } from "../../data/consts";
import Modal from "../../components/modal";
import Select from "react-select";

import * as XLSX from "xlsx"; // Import SheetJS
import { saveAs } from "file-saver";

export default function Invoices() {
  const language = useSelector((state) => state.language);
  const [fatoora, setFatoora] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: Infinity });
  const [loader, setLoader] = useState(false);
  const [combineFatoorah, setCombineFatoorah] = useState([]);
  const [fatoorahStatus, setFatooraStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // **تعديل الحالة لتخزين مصفوفة من معرفات الوكلاء**
  const [doctorIds, setDoctorIds] = useState([]); // بدلاً من doctorId
  const [agent, setAgent] = useState([]);
  const [totalPrice, setTotalPrice] = useState({});
  const [tahweelData, setTahweelData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [AllTehweelsPrice, setAllTehweelsPrice] = useState(0);

  const headers = [
    // { label: "#", dataIndex: "agent_id" },
    {
      label: language === "ar" ? "اسم المنتج" : "Product Name",
      dataIndex: "product_name",
    },
    {
      label: language === "ar" ? "الحالة" : "Status",
      dataIndex: "paid",
      type: "children",
      children: ({ row }) => <span>{row?.paid ? row?.paid : "Un Paid"}</span>,
    },
    {
      label: language === "ar" ? "اسم الوكيل" : "User Name",
      type: "children",
      children: ({ row }) => <span>{row?.agent_info?.agent_name}</span>,
    },
    {
      label: language === "ar" ? "رقم الفاتورة" : "Invoice Number",
      dataIndex: "fatoorah_id",
    },
    {
      label: language === "ar" ? "المبلغ" : "Amount",
      dataIndex: "price",
      type: "children",
      children: ({ row }) => <span>{parseFloat(row?.price).toFixed(2)}</span>,
    },
    {
      label: language === "ar" ? "الخصم من فواتيرك" : "Discount Amount",
      dataIndex: "discount_amount",
    },
    {
      label: language === "ar" ? "مكسب بسيطة" : "Baseeta Discount",
      dataIndex: "baseeta_discount",
      type: "children",
      children: ({ row }) => (
        <span>
          {parseFloat(row?.submission_price || 0) *
            parseFloat(row?.baseeta_discount || 0)}
        </span>
      ),
    },
    {
      label: language === "ar" ? "مصاريف إدارية" : "Administrative Expenses",
      dataIndex: "administrative_expenses",
      type: "children",
      children: ({ row }) => (
        <span>
          {(
            parseFloat(row?.price || 0) *
            (parseFloat(row?.administrative_expenses || 0) / 100)
          ).toFixed(1)}
        </span>
      ),
    },

    {
      label: language === "ar" ? "المبلغ الصافي" : "Total Amount",
      dataIndex: "submission_price",
      type: "children",
      children: ({ row }) => (
        <span>
          {(
            (parseFloat(row?.submission_price) || 0) -
            (parseFloat(row?.discount_amount) || 0) -
            (parseFloat(row?.submission_price) || 0) *
            (parseFloat(row?.baseeta_discount) || 0)
          ).toFixed(2)}
        </span>
      ),
    },
    {
      label: language === "ar" ? "شركة التمويل" : "Finance company",
      dataIndex: "payment_method",
    },
    {
      label: language === "ar" ? "تاريخ الانشاء" : "Created At",
      dataIndex: "created_at",
      type: "children",
      children: ({ row }) => <p>{row?.created_at}</p>,
    },
    {
      label: language === "ar" ? "ايميل العميل" : "Agent Email",
      dataIndex: "agent_email",
      type: "children",
      children: ({ row }) => <p>{row?.agent_info?.agent_email}</p>,
    },
  ];

  useEffect(() => {
    handleGetAllData();
    getAgents();
  }, []);

  useEffect(() => {
    if (doctorIds.length > 0 || fatoorahStatus || startDate || endDate) {
      applyFilters();
    } else {
      setFilteredData(fatoora);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorIds, fatoorahStatus, startDate, endDate, fatoora]);

  const handleGetAllData = async () => {
    setLoader(true);
    try {
      const res = await axios.post(
        "https://baseeta-form.com/basita/getFatoorah.php",
        {
          start_date: startDate,
          end_date: endDate,
        }
      );
      if (res?.data) {
        setFatoora(res.data);
        setFilteredData(res.data);
      } else {
        toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
      }
    } catch (error) {
      console.error(error);
      toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
    } finally {
      setLoader(false);
    }
  };

  const getAgents = async () => {
    try {
      const select = await axios.get(base_url + "select_agents.php");
      setAgent(select?.data?.message || []);
    } catch (error) {
      console.error(error);
      toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
    }
  };

  const applyFilters = () => {
    let filtered = [...fatoora];

    if (doctorIds.length > 0) {
      filtered = filtered.filter(
        (item) =>
          doctorIds.filter(
            (p_item) => parseInt(p_item) == item?.agent_info?.agent_id
          )?.length
      );
    }

    // تصفية حسب حالة الفاتورة
    if (fatoorahStatus) {
      filtered = filtered.filter((item) => {
        if (!item?.paid) {
          item.paid = "unpaid";
        }
        return item?.paid.toLowerCase() == fatoorahStatus.toLowerCase();
      });
    }

    // تصفية حسب نطاق التاريخ
    if (startDate) {
      filtered = filtered.filter(
        (item) => new Date(item.created_at) >= new Date(startDate)
      );
    }
    if (endDate) {
      filtered = filtered.filter(
        (item) => new Date(item.created_at) <= new Date(endDate)
      );
    }

    // **حساب الإجماليات لكل وكيل بشكل منفصل**
    const totals = {};
    filtered.forEach((item) => {
      const agentId = item?.agent_info?.agent_id;
      if (!totals[agentId]) {
        totals[agentId] = 0;
      }
      if (item?.paid == "paid") {
        console.log(item);
        const submissionPrice = parseFloat(item?.submission_price) || 0;
        const discountAmount = parseFloat(item?.discount_amount) || 0;
        const baseetaDiscount = parseFloat(item?.baseeta_discount) || 0;
        const discountedPrice =
          submissionPrice - submissionPrice * baseetaDiscount;
        totals[agentId] += discountedPrice;
      } else {
        console.log(item);
        const submissionPrice = parseFloat(item?.submission_price) || 0;
        const discountAmount = parseFloat(item?.discount_amount) || 0;
        const baseetaDiscount = parseFloat(item?.baseeta_discount) || 0;
        const discountedPrice =
          submissionPrice - submissionPrice * baseetaDiscount;
        totals[agentId] += discountedPrice;
      }
    });

    setTotalPrice(totals);
    setAllTehweelsPrice(filtered[0]?.totalTehweelatPrice || 0);
    setFilteredData(filtered);
  };

  const exportToExcel = () => {
    if (!filteredData || filteredData.length === 0) {
      toast.error(
        language === "ar"
          ? "لا توجد بيانات للتصدير."
          : "No data available for export."
      );
      return;
    }

    // Flatten the data to handle nested agent_info
    const flattenedData = filteredData.map((item) => ({
      [language === "ar" ? "اسم المنتج" : "Product Name"]:
        item.product_name || "N/A",
      [language === "ar" ? "الحالة" : "Status"]: item.paid
        ? item.paid
        : language === "ar"
          ? "غير مدفوع"
          : "Un Paid",
      [language === "ar" ? "اسم الوكيل" : "User Name"]:
        item.agent_info?.agent_name || "N/A",
      [language === "ar" ? "رقم الفاتورة" : "Invoice Number"]:
        item.fatoorah_id || "N/A",
      [language === "ar" ? "المبلغ" : "Amount"]: parseFloat(
        item.price || 0
      ).toFixed(2),
      [language === "ar" ? "الخصم من فواتيرك" : "Discount Amount"]: parseFloat(
        item.discount_amount || 0
      ).toFixed(2),
      [language === "ar" ? "مكسب بسيطة" : "Baseeta Discount"]: (
        parseFloat(item.submission_price || 0) *
        parseFloat(item.agent_info?.baseeta_discount || 0)
      ).toFixed(2),
      [language === "ar" ? "مصاريف إدارية" : "Administrative Expenses"]: (
        parseFloat(item.price || 0) *
        (parseFloat(item.administrative_expenses || 0) / 100)
      ).toFixed(1),
      [language === "ar" ? "المبلغ الصافي" : "Total Amount"]: (
        parseFloat(item.submission_price || 0) -
        parseFloat(item.discount_amount || 0) -
        parseFloat(item.submission_price || 0) *
        parseFloat(item.agent_info?.baseeta_discount || 0)
      ).toFixed(2),
      [language === "ar" ? "شركة التمويل" : "Finance Company"]:
        item.payment_method || "N/A",
      [language === "ar" ? "تاريخ الانشاء" : "Created At"]:
        item.created_at || "N/A",
      [language === "ar" ? "ايميل العميل" : "Agent Email"]:
        item.agent_info?.agent_email || "N/A",
    }));
    // Create a worksheet from the flattened data
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");

    // Generate a buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the buffer
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    // Save the file using FileSaver
    saveAs(dataBlob, "Invoices.xlsx");
  };

  const addTahweel = async () => {
    setLoading(true);

    const taotalsSend = doctorIds.map((agentId) => {
      const agentInfo = agent.find((a) => a.agent_id === agentId);
      const total =
        totalPrice[agentId] -
        agentInfo?.transfers?.reduce(
          (acc, cur) => acc + parseFloat(cur?.total_price),
          0
        );
      return { total: totalPrice[agentId], id: agentId, finalTotal: total };
    });

    console.log();
    // return;
    console.log(filteredData)
    const data = {
      fatooras: filteredData?.map(item => item?.fatoorah_id)?.join("###"),
      tahweelat: taotalsSend
        ?.map(
          (item) =>
            `${item?.id}##${item?.finalTotal ? item?.total : 0}##${item?.finalTotal || 0
            }`
        )
        ?.join("***"),
    };

    try {
      const res = await axios.post(
        "https://baseeta-form.com/basita/add_tahweel.php",
        data
      );
      if (res?.data?.status === "success") {
        toast.success("تمت إضافة التحويل بنجاح");
        setTahweelData(null);
        handleGetAllData();
        getAgents();
      } else {
        toast.error(res?.data?.message || "حدث خطأ");
      }
    } catch (error) {
      console.error(error);
      toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-container con-h">
      <DefaultLayout>
        <div className="childs">
          <div className="header">
            <ContentNav
              head={language === "ar" ? "فواتيرك" : "Your Invoices"}
            />
            <div className="export-container">
              <button className="btn btn-primary" onClick={exportToExcel}>
                {language === "ar" ? "تصدير إلى Excel" : "Export to Excel"}
              </button>
            </div>
            <div className="searchContainer">
              <label htmlFor="search">
                {language === "ar" ? "ابحث هنا" : "Search here"}
              </label>
              {/* يمكن إضافة حقل البحث هنا إذا لزم الأمر */}
            </div>
          </div>
        </div>
        <div className="filterTable d-flex flex-wrap">
          <div className="filters" style={{ width: "min(850px, 100%)" }}>
            <label>{language === "ar" ? "تاريخ البدء" : "Start Date"}</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />

            <label>{language === "ar" ? "تاريخ الانتهاء" : "End Date"}</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />

            <button onClick={handleGetAllData}>
              {language === "ar" ? "بحث" : "Search"}
            </button>
          </div>

          <div className="filters" style={{ width: "min(850px, 100%)" }}>
            <div className="inputField">
              <label htmlFor="doctorId">
                {language === "ar" ? "اختيار الوكيل" : "Select Agents"}
              </label>
              <Select
                isMulti // **إضافة خاصية الاختيار المتعدد**
                onChange={
                  (selectedOptions) =>
                    setDoctorIds(selectedOptions.map((option) => option.value)) // **تحديث حالة الوكلاء المحددين**
                }
                options={
                  agent && agent?.length
                    ? agent.map((item) => ({
                      label: item.name,
                      value: item.agent_id,
                    }))
                    : []
                }
                placeholder={
                  language === "ar" ? "اختر وكلاء..." : "Select agents..."
                }
              />
              {/* <select
                name="doctorId"
                id="doctorId"
                onChange={(e) => setDoctorId(e.target.value || null)}
              >
                <option value="">{language === "ar" ? "الكل" : "All"}</option>
                {agent &&
                  agent.map((item) => (
                    <option key={item.agent_id} value={item.agent_id}>
                      {item.name}
                    </option>
                  ))}
              </select> */}
            </div>
            <div className="inputField">
              <label htmlFor="fatoorahStatus">
                {language === "ar" ? "حالة الدفع" : "Invoice Status"}
              </label>
              <select
                name="fatoorahStatus"
                id="fatoorahStatus"
                onChange={(e) => setFatooraStatus(e.target.value || "")}
              >
                <option value="">{language === "ar" ? "الكل" : "All"}</option>
                <option value="paid">
                  {language === "ar" ? "مدفوع" : "Paid"}
                </option>
                <option value="unpaid">
                  {language === "ar" ? "غير مدفوع" : "UnPaid"}
                </option>
              </select>
            </div>
            {(doctorIds.length > 0 ||
              fatoorahStatus ||
              startDate ||
              endDate) && (
                <div
                  className="d-flex align-items-center justify-content-between flex-column"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  {/* **عرض الإجماليات لكل وكيل** */}
                  {doctorIds.map((agentId) => {
                    const agentInfo = agent.find((a) => a.agent_id === agentId);
                    const total =
                      totalPrice[agentId] -
                      (fatoorahStatus == "paid"
                        ? agentInfo?.transfers?.reduce(
                          (acc, cur) => acc + parseFloat(cur?.total_price),
                          0
                        )
                        : 0);
                    return (
                      <span key={agentId}>
                        {language === "ar"
                          ? `إجمالي فواتير ${agentInfo?.name || "الوكيل"}: `
                          : `Invoices Total Price for ${agentInfo?.name || "Agent"
                          }: `}
                        {total.toFixed(2)}
                      </span>
                    );
                  })}
                  {/* **زر التحويل لكل وكيل عند اختيار حالة الدفع مدفوعة** */}
                  {doctorIds.length > 0 && fatoorahStatus === "paid" && (
                    <button
                      style={{ fontSize: "14px" }}
                      className="btn btn-success"
                      onClick={() =>
                        setTahweelData({
                          agent_ids: doctorIds,
                          total_price:
                            Object.values(totalPrice).reduce(
                              (acc, cur) => acc + cur,
                              0
                            ) - AllTehweelsPrice,
                        })
                      }
                    >
                      {language === "ar" ? "تحويل لوكلاء" : "Transfer Money"}
                    </button>
                  )}
                </div>
              )}
          </div>
        </div>
        {loader ? (
          <Loader size="lg" />
        ) : (
          <Table headers={headers} body={filteredData} />
        )}
        <Modal
          open={tahweelData}
          toggle={() => setTahweelData(null)}
          headerTitle={
            language === "ar" ? "تحويل لوكيل" : "Transfer Money To Agent"
          }
          children={
            <>
              <div
                className="d-flex  justify-content-between flex-column gap-3 py-3"
                style={{ width: "100%", marginTop: "10px" }}
              >
                {doctorIds.map((agentId) => {
                  const agentInfo = agent.find((a) => a.agent_id === agentId);
                  const total =
                    totalPrice[agentId] -
                    agentInfo?.transfers?.reduce(
                      (acc, cur) => acc + parseFloat(cur?.total_price),
                      0
                    );
                  return (
                    <span key={agentId}>
                      <span
                        style={{
                          width: "min(200px, 100%)",
                          marginTop: "10px",
                          display: "inline-block",
                        }}
                      >
                        {" "}
                        {language === "ar"
                          ? `إجمالي فواتير ${agentInfo?.name || "الوكيل"}: `
                          : `Invoices Total Price for ${agentInfo?.name || "Agent"
                          }: `}
                      </span>
                      <input
                        type="text"
                        value={total || 0}
                        readOnly
                        style={{ outline: "none" }}
                      />
                    </span>
                  );
                })}
              </div>
              {/* <div className="inputField">
                <label> {language === "ar" ? "المبلغ" : "Amount"} </label>
              </div> */}

              <div className="flex-box">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    if (!loading) addTahweel();
                  }}
                >
                  {loading ? (
                    <Loader />
                  ) : language === "ar" ? (
                    "تأكيد"
                  ) : (
                    "Confirm"
                  )}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => setTahweelData(null)}
                >
                  {language === "ar" ? "إلغاء" : "Cancel"}
                </button>
              </div>
            </>
          }
        />
      </DefaultLayout>
    </div>
  );
}
