export const SideNavData = [
  {
    path: "/",
    label: "الرئيسية",
    label_en: "Home",
    icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1698247052/home_in8i1t.png",
  },
  {
    path: "/Doctor",
    label: "الوكيل",
    label_en: "Agent",
    icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1698247299/doctor_teemu0.png",
  },
  {
    path: "/ArchivedDoctor",
    label: "أرشيف الوكلاء",
    label_en: "Archiver Doctors",
    icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1698247299/doctor_teemu0.png",
  },
  {
    path: "/persons",
    label: "الأشخاص",
    label_en: "Persons",
    icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1698247299/doctor_teemu0.png",
  },
  {
    path: "/ArchiverPersons",
    label: "أرشيف الأشخاص",
    label_en: "ArchiverPersons ",
    icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1698247299/doctor_teemu0.png",
  },
  {
    path: "/invoice",
    label: "فواتيرك",
    label_en: "Your Invoices",
    icon: "",
  },
  {
    path: "/Tahweelat",
    label: "التحويلات",
    label_en: "Transfers",

    icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1693607540/sections_kvdjho.png",
  },
  // {
  //   path: "/website_data",
  //   label: "بيانات الموقع",
  //   icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1693644501/analytics_oogmfi.png",
  // },
  // {
  //   path: "/user_data",
  //   label: "بيانات الشخص",
  //   icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1693644663/personal-information_n4lwuo.png",
  // },
  // {
  //   path: "/services",
  //   label: "الخدمات",
  //   icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1693645822/24-hours_puqxzb.png",
  // },
  // {
  //   path: "/how_work",
  //   label: "طرق العمل",
  //   icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1693649787/work_tqdua2.png",
  // },
];
