import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import "./style.css";
import QRCode from "qrcode";
import axios from "axios";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import { useSelector } from "react-redux";
import Modal from "../../components/modal";

function AddDoctor() {
  const language = useSelector((state) => state.language);
  const [imageSrc, setImageSrc] = useState(false);
  const [image, setImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrCode, setQRCode] = useState(false);
  const [showQrUploader, setShowQrUploader] = useState(false);
  const [QrUploader, setQrUploader] = useState(false);
  const [qrCodeLink, setQrCodeLink] = useState(false);
  const [qrCodeFile, setQrCodeFile] = useState(false);
  const changeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const source = e.target.files[0];
      const objectUrl = URL.createObjectURL(source);
      setImageSrc(objectUrl);
      setImage(e.target.files[0]);
    }
  };
  const deleteImage = () => {
    setImage(false);
    setImageSrc(false);
  };

  const addDoctor = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    formData.append("image", image);
    const uploadImage = await axios.post(
      "https://baseeta-form.com/basita/image_uplouder.php    ",
      formData
    );
    if (uploadImage?.data && uploadImage?.data?.length) {
      const data = {
        name: form?.doctor_name?.value?.trim(),
        phone: form?.doctor_phone?.value?.trim(),
        password: form?.password?.value?.trim(),
        baseeta_discount: form?.baseeta_discount?.value?.trim(),
        image: uploadImage?.data,
        email: form?.email?.value?.trim(),
      };
      await axios
        .post("https://baseeta-form.com/basita/add_agent.php", data)
        .then((res) => {
          if (res?.data?.status == "success") {
            toast.success("تمت إضافة الوكيل بنجاح");
            setShowQrUploader(res?.data?.message);
            generateQrCode(res?.data?.message);
            form.reset();
            setImage(false);
            setImageSrc(false);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
      toast.error("يوجد مشكلة في الصورة برجاء اختيار صورة أخرى");
    }
  };

  const QRUpload = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const qr = await axios.post(
      "https://baseeta-form.com/basita/qr_uplouder.php",
      formData
    );
    setQrCodeLink(qr);
  };

  useEffect(() => {
    if (showQrUploader) generateQrCode();
  }, [showQrUploader]);
  const generateQrCode = async (id) => {
    const qr = await QRCode.toDataURL(
      `https://baseeta-form.com/?q=${showQrUploader}`
    );
    getQRCodeFile(qr);
    setQRCode(qr);
  };

  const getQRCodeFile = (qr) => {
    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl?.split(","),
        mime = arr[0]?.match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr?.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr?.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    //Usage example:
    var file = dataURLtoFile(qr, "QrCode.png");
    setQrCodeFile(file);
    QRUpload(file);
  };

  const uploadQrCodeAgent = async () => {
    setQrUploader(true);
    await axios
      .post("https://baseeta-form.com/basita/qr_update.php", {
        agent_id: showQrUploader,
        url: qrCodeLink?.data,
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success("تم تحديث الصورة بنجاح");
          setShowQrUploader(false);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error("Error: " + err.message);
      })
      .finally(() => {
        setQrUploader(false);
      });
  };

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav
              head={language == "ar" ? "إضافة بيانات الوكيل" : "Add Agent Data"}
            />
            <form action="" onSubmit={!loading ? addDoctor : () => null}>
              <div className="formRow">
                <div className="inputField">
                  <label htmlFor="doctor_name">
                    {language == "ar" ? "اسم الوكيل" : "Agent Name"}
                  </label>
                  <input type="text" id="doctor_name" name="doctor_name" />
                </div>
                <div className="inputField">
                  <label htmlFor="doctor_phone">
                    {language == "ar" ? "رقم التليفون" : "Mobile"}{" "}
                  </label>
                  <input type="text" id="doctor_phone" name="doctor_phone" />
                </div>
                <div className="inputField">
                  <label htmlFor="doctor_phone">
                    {language == "ar" ? "خصم بسيطة" : "Baseeta Discount"}{" "}
                  </label>
                  <input type="text" id="baseeta_discount" name="baseeta_discount" />
                </div>
                <div className="inputField">
                  <label htmlFor="email">
                    {language == "ar"
                      ? "بريد الكتروني لاستقبال الاشعارات"
                      : "Notification Email"}
                  </label>
                  <input type="text" id="email" name="email" />
                </div>{" "}
                <div className="inputField">
                  <label htmlFor="password">
                    {language == "ar" ? "كلمة السر" : "Password"}
                  </label>
                  <input type="text" id="password" name="password" />
                </div>
              </div>

              <div className="inputField type_image">
                <label htmlFor="image">
                  {language == "ar" ? "صورة الوكيل" : "Agent Image"}
                </label>
                <input
                  type="file"
                  id="image"
                  lang="ar"
                  name="image"
                  onChange={changeImage}
                />
              </div>
              {imageSrc ? (
                <div
                  className="uploaded_image"
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "flex-start",
                    margin: "20px 0",
                  }}
                >
                  <img
                    src={imageSrc}
                    alt=""
                    width={200}
                    style={{ borderRadius: 5 }}
                  />
                  <span className="btn btn-danger" onClick={deleteImage}>
                    {language == "ar" ? "حذف" : "Delete"}
                  </span>
                </div>
              ) : null}
              {!loading ? (
                <button className="btn btn-success">
                  {language == "ar" ? "إضافة الوكيل" : "Agent Name"}
                </button>
              ) : (
                <Loader />
              )}
            </form>
            <Modal
              open={showQrUploader}
              toggle={setShowQrUploader}
              headerTitle={
                language == "ar"
                  ? "تأكيد رفع ال QR Code"
                  : "Cofirm Agent QR Code"
              }
              children={
                <div>
                  <img src={qrCode} alt="QRCode" />
                  <button
                    className="btn btn-success"
                    onClick={() => (!QrUploader ? uploadQrCodeAgent() : null)}
                  >
                    {!QrUploader ? (
                      language == "ar" ? (
                        "تأكيد وحفظ"
                      ) : (
                        "Upload"
                      )
                    ) : (
                      <Loader />
                    )}
                  </button>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default AddDoctor;
